<template> 
    <div class="cadre">   
         
        <div  class = "margin">
            <transition-group class="tree" name="list" tag="ul">                            
                <li class="menu-brique list" v-for="folder in filtrerFolder(arborescence, documentFolderId)" :key="folder.id">
                    <div v-if="rafraichir">
                        document importé avec succès
                    </div>                                
                    <a v-bind:class="(etendreTab[folder.id] === true) ? 'a stella' : 'a '" v-on:click="sendEtendre(folder.id,documentFolderId )" >
                        <div>
                            <i v-bind:class="(etendreTab[folder.id] === true) ? 'far fa-folder-open' : 'far fa-folder-close'"></i>
                            {{folder.lib}}
                        </div>                             
                        <div v-bind:class="(folder.document_folder_statut == 'INC') ? 'couleur1':(folder.document_folder_statut == 'CAV') ? 'couleur2':'couleur3'">
                            {{afficherStatut(folder)}}
                            <i v-bind:class="(folder.document_folder_statut == 'INC') ? 'far fa-times-circle':(folder.document_folder_statut == 'CAV') ? 'far fa-check-circle':'fas fa-check-circle'" ></i>
                        </div>
                    </a>                   
                    <Transition name = "fade" >                                             
                        <div v-if="etendreTab[folder.id] === true" >              
                            <div v-if="folderFilsExiste(folder.id) ">
                                <ArborescenceFolder 
                                    :arborescence="filtrerFolderRec(arborescence,folder.id)" 
                                    :documentFolderId="parseInt(folder.id)" 
                                    :listeDocument="listeDocument"
                                    :etendreTab="etendreTab">
                                </ArborescenceFolder>       
                            </div> 
                            <div v-if="afficherListe==folder.id">               
                                <ListeDocument                   
                                    :listeDocument ="filtrerDocument(listeDocument,folder.id)"
                                    :titreFolder="folder.lib" 
                                    :folderId="parseInt(folder.id)">                                           
                                </ListeDocument> 
                            </div>       
                        </div>
                    </Transition>                                            
                </li>
            </transition-group>               
        </div> 
        <div v-if="(documentFolderId == 0 && listeDocument)">
            <ListeDocument                   
            :listeDocument ="filtrerDocument(listeDocument,documentFolderId)"
            :titreFolder="'A la racine'" 
            :folderId="0">                                           
            </ListeDocument>      
        </div>                                                     
    </div>   
</template>
    
<script>
    import ListeDocument from './ListeDocument.vue';      
    export default {
        name: "ArborescenceFolder",
        components: { ListeDocument },
        props: {
            arborescence: Array,
            documentFolderId: Number,
            listeDocument: Object,
            etendreTab: Object,
                 
        },
        data(){
            return {
                listeFolderAlaRacine:[], 
                afficherListe:0, 
                rafraichir:false      
            }
        },
        created() {
            this.$root.$on('gestionFermeture',(folderId) =>{      
                this.arborescence.forEach(element => {
                    if (element.document_folder == folderId) {
                        if(this.etendreTab[element.id] === null || this.etendreTab[element.id] === true){
                            this.etendreTab[element.id] = false          
                        }
                        this.gestionFermeture(element.id)
                    }
                })              
            }),
            this.$root.$on('gestionFermetureListe',(folderId) =>{      
                this.afficherListe = folderId                  
            })
            this.$root.$on('afficherDocument',(document) =>{     
                this.rafraichir=true  
                setTimeout(() => {}, "1000")
                this.rafraichir=false    
            })     
        },  
        methods: {
            //methode pour obtenir la liste des sous-folder du folder courant 
            filtrerFolder(arr,folderId) {
                let arboresTrie = []
                    arr.forEach(element => {
                        if (element.document_folder == folderId) {
                            arboresTrie.push(element);
                        }           
                    });
                
                return arboresTrie;
            },
            filtrerFolderRec(arr,folderId) { 
                let arboresTrie = []
                    arr.forEach(element => {
                        if (element.document_folder == folderId) {
                            arboresTrie.push(element);
                            if(this.folderFilsExiste(element.id)){
                                let arboresTrieBis = this.filtrerFolderRec(this.arborescence,element.id)
                                arboresTrieBis.forEach(element=>{
                                    arboresTrie.push(element)
                                })
                            }
                        }          
                    });
                return arboresTrie       
            },
            //methode pour obtenir la liste des id  folders se situant à la racine de l'arborescence courant
            filtrerFolderALaRacine(documentFolderId){
                this.listeFolderAlaRacine = []
                this.arborescence.forEach(element => {
                    if (element.document_folder == documentFolderId) {
                        this.listeFolderAlaRacine.push(element.id);
                    }
                })
                return this.listeFolderAlaRacine

            },
            //methode qui renvoie true si un element est dans une liste
            estDansLaListe(arr,folderId){
                let i = 0
                while(i < arr.length && !(arr[i] == folderId)){
                    i ++
                }
                if(i < arr.length){
                    return true
                }
                return false
            },
            //methode pour pour obtenir la liste des documents du folder courant
            filtrerDocument(arr, folderId) {
                let listeDocTrie
                listeDocTrie = arr[folderId];
                return listeDocTrie;
            },
            //methode pour la gestion de fermeture du folder courant ainsi que ses sous folder
            gestionFermeture(folderId){
                this.$root.$emit('gestionFermeture',folderId) 
            },
            gestionFermetureListe(folderId){
                this.$root.$emit('gestionFermetureListe',folderId) 
            },
            //methode pour la gestion d'extention de l'affichage des sous-folder  lorsque l'on ouvre/ferme  le folder courant
            sendEtendre(folderId ,documentfolderId) {    
                if (this.etendreTab[folderId] === null  ) {
                    this.etendreTab[folderId] = false;         
                }
                this.etendreTab[folderId] = !this.etendreTab[folderId];   
                if(this.etendreTab[folderId] == false){
                    this.gestionFermeture(folderId)
                    this.gestionFermetureListe(documentfolderId)    
                }
                else {
                    this.gestionFermetureListe(folderId)    
                    if(this.estDansLaListe(this.filtrerFolderALaRacine(documentfolderId),folderId)){
                        this.listeFolderAlaRacine.forEach(element => {         
                            if(element != folderId){
                                this.etendreTab[element] = false
                                this.gestionFermeture(element)    
                            }                                   
                        })
                    }                      
                }          
                
                
                   
            },
            //methode qui renvoie true si le folder courant possède des sous-folders
            folderFilsExiste(folderId){
                let i = 0;
                while((i < this.arborescence.length)&& !(this.arborescence[i].document_folder  == folderId)){
                     i++
                }
                if(i < this.arborescence.length){
                    return true
                }
                return false 
            },
            //methode qui renvoie true si le folder courant possède une liste de document
            listeDocumentExiste(folderId){
                
                if(this.listeDocument[folderId] != null){
                    return true
                }
                return false

            },
            //methode pour afficher le statut courant d'un folder 
            afficherStatut(folder) {
                let statut = "";
                switch (folder.document_folder_statut) {
                    case "INC":
                        statut = "Incomplet";
                        break;
                    case "COV":
                        statut = "Complet(validé)";
                        break;
                    case "CAV":
                        statut = "Complet(à vérifier)";
                        break;
                    default:
                        statut = "";
                        break;
                }
                return statut;
            }
        },  
    }
</script>
    
<style lang="scss" scoped>
    @import "../../assets/scss/colors";
    .margin{
        margin-top: -21px;
        margin-bottom: 30px;

    }   
    .cadre {
        padding: 20px;
        border: 1px solid #ffffff !important ;
        height: 100% !important;     
    }   
    .fa-folder-open:before {
        margin-right: 5px;
        font-weight: bold;
        content: "\F07C";
    }
    .fa-folder-close:before {
        margin-right: 5px;
        content: "\f07b ";
    }

    .fa-arrow:before {
    content: "\f35b";
    }

        .couleur1{
        color:crimson
    }
        .couleur2{
        color:blue
    }
        .couleur3{
        color:forestgreen
    }
    .fa-check-circle{
        content: "\f058";
            margin-left: 15px;
    }
    .fa-times-circle{
        content:"\f057";
        margin-left: 15px;
    }
/* original idea http://www.bootply.com/phf8mnMtpe */

    .tree {
        min-height:20px;
        padding:19px;
        margin-bottom:-40px;
        background-color:#fbfbfb;
        -webkit-border-radius:4px;
        -moz-border-radius:4px;
        border-radius:4px;
        -webkit-box-shadow:inset 0 1px 1px rgba(61, 22, 122, 0.05);
        -moz-box-shadow:inset 0 1px 1px rgba(61, 22, 122, 0.05);
        box-shadow:inset 0 1px 1px rgba(61, 22, 122, 0.05);
    }
    .tree li {
        list-style-type:none;
        margin:0;
        padding:6px 5px 0 5px;
        position:relative
    }
    .tree li::before, .tree li::after {
        content:'';
        left:-20px;
        position:absolute;
        right:auto
    }
    .tree li::before {
        border-left:2px solid  #1a73e8;
        bottom:50px;
        height:100%;
        top:0;
        width:1px
    }
    .tree li::after {
        border-top:2px solid  #1a73e8;
        height:20px;
        top:30px;
        width:25px
    }
    .tree li span {
        -moz-border-radius:5px;
        -webkit-border-radius:5px;
        border:2px solid  #1a73e8;
        border-radius:5px;
        display:inline-block;
        padding:3px 8px;
        text-decoration:none
    }
    .tree li.parent_li>span {
        cursor:pointer
    }
    .tree>ul>li::before, .tree>ul>li::after {
        border:0
    }
    .tree li:last-child::before {
        height:30px
    }
    .tree li.parent_li>span:hover, .tree li.parent_li>span:hover+ul li span {
        background:#eee;
        border:1px solid #94a0b4;
        color:#000
    }    
    
    .fade-enter-active,
    .fade-leave-active {
        transition:  all  1s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
    opacity: 0;
    
    }
    .list-move, /* apply transition to moving elements */
    .list-enter-active,
    .list-leave-active {
    transition: all 1s ;
    }

    .list-enter-from,
    .list-leave-to {
    opacity: 0;
    transition: all 1s ;
    transform: translateX(30px);
    }

    /* ensure leaving items are taken out of layout flow so that moving
    animations can be calculated correctly. */
    .list-leave-active {
    position: absolute;
    }
    .a{
        border-radius: 5px !important;
         box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1); 
        
    } 
    .stella{
        
        background-color: #D5E8FA;
        font-weight: bold;
        
    }
    


    
</style>
    